import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import WithLogin from './routes/login/WithLogin';

export default function App() {
  return (
    <div className="bg-black w-full h-full min-h-screen fixed top-0 left-0 overscroll-none">
      <Router>
        <Switch>
          <Route path="*" component={WithLogin} />
        </Switch>
      </Router>
    </div>
  );
}
