import styled from 'styled-components';
import DesktopBG from '../../assets/images/desktop-bg.png';
import MobileBG from '../../assets/images/mobile-bg.png';

const VideoContainer = styled.iframe`
  @media (max-width: 980px) {
    height: 275px;
    width: 480px;
  }

  @media (max-width: 500px) {
    width: 100%;
    height: 410px;
  }
`;

const Container = styled.div`
  background-image: url(${MobileBG});
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 768px) {
    background-image: url(${DesktopBG});
  }
`;

export default function Home() {
  return (
    <Container className="flex flex-col justify-center items-center font-simpletype font-black h-full p-0 md:px-56 md:py-24">
      <VideoContainer
        className="w-full h-full"
        src="https://www.youtube.com/embed/Yr-kFHJGfOA"
        title="numb"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Container>
  );
}
