import { useState } from 'react';
import Lock from './lock-solid.svg';

export const fakeAuth = {
  isAuthenticated: false,

  authenticate(callback: () => void) {
    this.isAuthenticated = true;
    sessionStorage.setItem('isAuthenticated', JSON.stringify(this.isAuthenticated));
    setTimeout(callback, 100); // fake async
  },
  signout(callback: () => void) {
    this.isAuthenticated = false;
    sessionStorage.removeItem('isAuthenticated');
    setTimeout(callback, 100); // fake async
  },
};

export default function Login(props: any) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const enteredLocation = props.history.location.state?.from.pathname;

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    fakeAuth.authenticate(() => {
      if (password === process.env.REACT_APP_SECRET) {
        setPassword('');
        window.location.pathname = enteredLocation ? enteredLocation : '/';
      } else {
        setError('Wrong password, please try again.');
        fakeAuth.signout(() => {
          //
        });
      }
    });
  }

  if (sessionStorage.getItem('isAuthenticated')) {
    window.location.pathname = '/';
    return null;
  }

  return (
    <div
      className="w-full h-full flex justify-center items-center flex-col min-h-screen"
      style={{ background: '#f2f2f2' }}
    >
      <div className="w-16 h-16 p-5 bg-none rounded-full border-2 border-gray-300 leading-0">
        <img src={Lock} alt="lock" />
      </div>
      <form className="mt-5" onSubmit={handleSubmit}>
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          className="px-3 py-3 block outline-none focus:border-blue-300 focus:border"
          style={{ width: '300px' }}
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        {!!error && <span className="text-red-500 text-lg">{error}</span>}
      </form>
    </div>
  );
}
