import { Route, Switch } from 'react-router-dom';
import { Home } from '../../app/index';
import PageNotFound from '../../app/PageNotFound/PageNotFound';
import Login from './Login';
import PrivateRoute from './PrivateRoute';

export default function WithLogin() {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      <Route exact path="/login" component={Login} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
}
