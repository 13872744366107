import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute({ component: Component, ...rest }: any) {
  const session = sessionStorage.getItem("isAuthenticated");

  return (
    <Route
      {...rest}
      render={(props: any) =>
        !!session ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
